import { FormattedMessage, injectIntl } from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import JSONData from '../../conf/label-maker.json';
import { setBorder as setBorderAction } from '../../state/actions';
import { getLabelBorder } from '../../state/selectors';
import RadioButton from '../radio-button';
import labelMakerBorderStyles from './label-maker-border.module.scss';

const LabelMakerBorder = ({ currentBorder, setBorder }) => {
  function setRadio(event) {
    setBorder(event.target.value);
  }

  const colors = [];
  JSONData.colors.map((color) => colors.push(
    <RadioButton name="color" className="m-smd" value={color} checked={color === currentBorder} key={color} color={color} />,
  ));

  return (
    <form>
      <div className="notification is-info is-light has-icon">
        <span className="icon">
          <i className="fas fa-info-circle fa-lg" />
        </span>
        <span className="p-l-lg">
          <FormattedMessage id="label-maker.border.help" />
        </span>
      </div>
      <div className={`control has-text-centered ${labelMakerBorderStyles.container}`} onChange={setRadio.bind(this)}>
        {colors}
        <div>
          <label className="m-t-md button is-info is-light button-color" htmlFor="custom-color">
            <input type="color" name="custom-color" id="custom-color" defaultValue="#ffffff" />
            <span className="p-l-sm">
              <FormattedMessage id="label-maker.border.color-picker" />
            </span>
          </label>
        </div>
      </div>
    </form>
  );
};

LabelMakerBorder.propTypes = {
  currentBorder: PropTypes.string,
  setBorder: PropTypes.func,
};

const mapStateToProps = (state) => ({
  currentBorder: getLabelBorder(state),
});

const mapDispatchToProps = {
  setBorder: setBorderAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(LabelMakerBorder));
